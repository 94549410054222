<template>
  <div class="about">
    <Navbar/>
    <h1>VIDAR V2.0</h1>
    <p>Esta es la version 2.0 de VIDAR, se realizan mejoras en seguridad, migracion a framework
      modelo orientado a servicios API.
    </p>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
export default {
  components: {
    Navbar
  }
}
</script>
